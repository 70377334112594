<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedAnalyses"
      :headers="headers"
      table-class="table-striped table-hover"
      @search="search"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'add-analyse'}"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-questionnaire-examen-clinique"
          v-if="canAdd"
        >
          <i class="icofont icofont-plus" />
        </router-link>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment'
import { DATE_TYPE, COMPONENT_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType';
import DataTable from '../../../components/dataTable/local.vue'
import navbar from '../../../components/navbar.vue';
const Animal = () => import('../../../components/sanitaire/animal.vue')
const CreatedBy = () => import('../../../components/sanitaire/createdBy.vue')
const TypeAnalyse = () => import('../../../components/sanitaire/analyse/type.vue')
const Actions = () =>  import('../../../components/sanitaire/analyse/actions.vue')
export default {
    components: {navbar, DataTable},
    data(){
        return{
          navbarItems: [
            {
                libelle: 'Sanitaire'
            },
            {
                libelle: 'Examen Para-clinique',
            }
          ],
          pageIcon: 'la-flask',
          pageTitle: 'Examen Para-clinique',
          pageDescription: 'List des examens Para-cliniques', 
          searchAnalyses: []
        }
    },
    watch:{
      analyses:{
        handler(){
            this.searchAnalyses = this.analyses
        },
        deep: true
      }
    },
    mounted(){ this.searchAnalyses = this.analyses},
    methods: {
        ...mapMutations({

        }),
        search(payload){
          const query = payload.search
          if(query === '') this.searchAnalyses = this.analyses
          else{
            this.searchAnalyses = this.analyses.filter(analyse =>
              this.$options.filters.deepSearch(analyse, query)|| 
              this.$options.filters.deepSearch(this.animaux.find(animal => animal.uid === analyse.animal), query)
            )
          }
        }
    },
    computed: {
        ...mapGetters({
            analyses: 'analyse/demandes',
            animaux: 'identification/animaux',
            is_veterinaire: 'auth/is_veterinaire',
            is_super_admin: 'auth/is_super_admin',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
        }),
        sortedAnalyses(){
            return [...this.searchAnalyses].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        headers(){
            return  [
                {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                {label: 'Num Demande', name: 'demandNum', type: TEXT_TYPE},
                {label: 'Analyses', name: 'type', type: COMPONENT_TYPE, component: TypeAnalyse},
                {label: 'Date de la demande', name: 'date', type: DATE_TYPE},
                {label: 'Demandé par', name: 'createBy', type: COMPONENT_TYPE, component: CreatedBy},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
            ]
        },
        canAdd(){
          return this.is_super_admin || this.is_veterinaire || this.is_assistant_veto_major
        }
    }
    
}
</script>

<style>

</style>